<template>
  <div class="page-bind-mobile">
    <div class="login-bg">
      <img src="../../../assets/login/login_bg_new1.png" alt="" />
    </div>
    <div class="body-contianer">
      <div class="body-wrap">
        <div class="title">绑定手机号</div>

        <div class="err-msg" v-if="errMsg">{{ errMsg }}</div>

        <div class="ipt-wrap">
          <div class="ipt-group">
            <label>手机号</label>
            <el-input
              placeholder="请输入你的手机号码"
              v-model="phoneNum"
              class="input"
            >
              <el-select v-model="area" slot="prepend" placeholder="请选择">
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="`+${item.cipher}`"
                  :value="item.id"
                  >{{ item.en }}
                </el-option>
              </el-select>
            </el-input>
          </div>
          <div class="ipt-group">
            <label>验证码</label>
            <div class="send-code">
              <el-input
                placeholder="请输入你的验证码"
                v-model="smsCode"
                class="input"
              >
              </el-input>
              <el-button
                :disabled="smsSendFlag"
                type="primary"
                @click="handleSendCode"
              >
                {{ smsBtnText }}
              </el-button>
            </div>
          </div>
        </div>

        <div class="login-btn">
          <div class="submit">
            <el-button type="primary" round @click="handleBind">
              确 定
            </el-button>
          </div>
        </div>

        <div class="bottom-wrap">
          <div class="links">
            <a href="https://ptego.com/" target="_blank">关于我们</a>
            <a href="https://ptego.com/terms" target="_blank">隐私协议</a>
            <a href="https://ptego.com/terms" target="_blank">用户政策</a>
          </div>
          <div class="copyright">
            PTEGO Copyright © 2021 All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  apiCheckAreacode,
  apiBindMObileSendCode,
  apiWechatLoginBindMobile,
  apiAppleWebLogin
} from '@/api/api.js'
import { apiGoogleLogin } from '@/api/userInfo'
import axios from 'axios'

export default {
  data() {
    return {
      areaList: [], //区域列表

      phoneNum: '',
      password: '',
      area: '',
      smsCode: '',

      smsBtnText: '发送验证码',
      smsSendFlag: false,
      errMsg: ''
    }
  },
  created() {
    this.getAreaCode()
  },
  methods: {
    // 获取区域区号【选择国家区域】
    getAreaCode() {
      apiCheckAreacode().then((res) => {
        this.areaList = res.data
        this.area = (this.areaList[0] || {}).id
      })
    },
    handleSendCode() {
      if (this.timeId) {
        return false
      }

      const areaData = this.areaList.find((m) => m.id == this.area)
      if (!this.phoneNum.trim() || !eval(areaData.match).test(this.phoneNum)) {
        this.errMsg = '手机号码格式错误'
        return false
      }

      this.smsSendFlag = true
      apiBindMObileSendCode({
        phone: this.phoneNum,
        country_code: areaData.cipher
      }).then(({ code, data, msg }) => {
        if (code !== 200) {
          this.errMsg = msg
          this.smsSendFlag = false
          return false
        }

        this.codeKey = data.key
        this.$layer.msg('发送验证码成功')

        let second = 60
        this.smsBtnText = `(${second}s)再次发送`
        this.timeId = setInterval(() => {
          second -= 1
          this.smsBtnText = `(${second}s)再次发送`

          if (second === 0) {
            clearInterval(this.timeId)
            this.timeId = 0
            this.smsSendFlag = false
            this.smsBtnText = '发送验证码'
          }
        }, 1000)
      })
    },
    handleBind() {
      const areaData = this.areaList.find((m) => m.id == this.area)

      if (!this.phoneNum.trim() || !eval(areaData.match).test(this.phoneNum)) {
        this.errMsg = '手机号码格式错误'
        return false
      }

      if (this.$route.query.unionid) {
        this.wechatBind(areaData)
      } else if (this.$route.query.appleIdToken) {
        this.appleBind(areaData)
      } else if (this.$route.query.googleId) {
        this.googleBind(areaData)
      }
    },
    googleBind(areaData) {
      apiGoogleLogin({
        phone: this.phoneNum,
        country_code: areaData.cipher,
        sms_code: this.smsCode,
        google_id: this.$route.query.googleId
      }).then(({ code, data, msg }) => {
        if (code === 200) {
          localStorage.setItem('token', data.access_token)
          axios.defaults.headers.assceetoken = data.access_token

          this.$router.push({
            name: 'home'
          })
        } else {
          this.errMsg = msg
        }
      })
    },
    appleBind(areaData) {
      apiAppleWebLogin({
        phone: this.phoneNum,
        country_code: areaData.cipher,
        sms_code: this.smsCode,
        apple_id_token: this.$route.query.appleIdToken
      }).then(({ code, data, msg }) => {
        if (code === 200) {
          localStorage.setItem('token', data.access_token)
          axios.defaults.headers.assceetoken = data.access_token

          this.$router.push({
            name: 'home'
          })
        } else {
          this.errMsg = msg
        }
      })
    },
    wechatBind(areaData) {
      apiWechatLoginBindMobile({
        phone: this.phoneNum,
        country_code: areaData.cipher,
        sms_code: this.smsCode,
        unionid: this.$route.query.unionid
      }).then(({ code, data, msg }) => {
        if (code === 200) {
          localStorage.setItem('token', data.access_token)
          axios.defaults.headers.assceetoken = data.access_token

          this.$router.push({
            name: 'home'
          })
        } else {
          this.errMsg = msg
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-bind-mobile {
  display: flex;
  color: #3a3c4d;

  .login-bg {
    > img {
      height: 100vh;
    }
  }

  .body-contianer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -9%;

    .body-wrap {
      width: 380px;
      padding-top: 100px;

      .title {
        font-size: 18px;
        margin-top: 20px;
        font-weight: bold;
        padding-bottom: 14px;
      }

      .tip {
        font-size: 14px;
        color: #7c8084;
        padding-bottom: 14px;
      }

      .err-msg {
        padding: 6px 12px;
        color: #ff606d;
        background-color: #ffcfd3;
        border-radius: 5px;
      }

      .ipt-wrap {
        .ipt-group {
          padding-top: 20px;

          > label {
            padding-bottom: 10px;
            font-size: 14px;
          }

          .input {
            margin-top: 10px;

            ::v-deep .el-select {
              width: 74px;
            }

            ::v-deep .el-input-group__prepend {
              background-color: #fff;
            }
          }

          .send-code {
            display: flex;

            ::v-deep .el-button {
              height: 40px;
              min-width: 120px;
              background-color: #3b86ff;
              border-color: #3b86ff;
              margin-top: 10px;
              margin-left: 26px;

              &.is-disabled {
                color: #ffffff;
                background-color: #bcbccb;
                border-color: #bcbccb;
              }
            }
          }
        }
      }

      .login-btn {
        margin-top: 60px;

        .submit {
          ::v-deep .el-button {
            width: 100%;
            background-color: #3b86ff;
            border-color: #3b86ff;

            &.is-disabled {
              color: #fff;
              cursor: not-allowed;
              background-image: none;
              background-color: #a0cfff;
              border-color: #a0cfff;
            }
          }
        }

        .tip {
          font-size: 14px;
          margin-top: 10px;
          color: #7c8084;

          > a {
            color: #3b86ff;
            text-decoration: none;

            &:active,
            &:focus {
              text-decoration: none;
            }
          }
        }
      }

      .bottom-wrap {
        margin-top: 200px;
        text-align: center;

        .links {
          > a {
            width: 33%;
            display: inline-block;
            font-size: 14px;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.45);

            &:after,
            &:focus {
              outline: none;
            }
          }
        }

        .copyright {
          margin-top: 10px;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
}
</style>
